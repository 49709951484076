window.render_all_pdf = function (url) {
    $("iframe#preview-document-iframe").hide()
    $("#preview-pdf").find(".loading").show();
    if (navigator.userAgent.search("Firefox") > -1) {
        var thePdf = null;
        var scale = 1;

        $(".loading-canvas").show();
        var frame = $("#preview-document");
        frame.attr("src", url);
        $("#content-document").show();

        var loadingTask = pdfjsLib.getDocument(url);
        loadingTask.promise.then(function (pdf) {

            thePdf = pdf;
            var viewer = document.getElementById("preview-document");
            $("#cant-paginas-document").text(pdf.numPages + " páginas")
            for (var page = 1; page <= pdf.numPages; page++) {
                var canvas = document.createElement("canvas");
                canvas.className = 'pdf-page-canvas';
                viewer.appendChild(canvas);
                renderPage(page, canvas);
            }
            $(".loading-canvas").hide();


        });

        function renderPage(pageNumber, canvas) {
            thePdf.getPage(pageNumber).then(function (page) {
                var viewport = page.getViewport({scale: scale});
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                page.render({canvasContext: canvas.getContext('2d'), viewport: viewport});
            });
        }

    } else {
        var frame = $("#preview-document-iframe");
        frame.attr("src", url);
        $("#content-document").show();
    }

}

window.btnForceDownload = function (e) {
    var _this = $(e);
    var fileName = _this.data('nombre');
    var fileURL = _this.data('url');
    if (!window.ActiveXObject) {
        var save = document.createElement('a');
        save.href = fileURL;
        save.target = '_blank';
        var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
        save.download = fileName || filename;
        if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
            document.location = save.href;
// window event not working here
        } else {
            var evt = new MouseEvent('click', {
                'view': window,
                'bubbles': true,
                'cancelable': false
            });
            save.dispatchEvent(evt);
            (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
        var _window = window.open(fileURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName || fileURL)
        _window.close();
    }
};
