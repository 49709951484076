export default class CkeditorUploader {
    constructor( loader, tipo ) {
        // CKEditor 5's FileLoader instance.
        this.loader = loader;
        this.tipo = tipo;
        // URL where to send files.
        this.url = '/attachments/upload_ckeditor/' + tipo;
        this.modelo_token = "";
        this.modelo = "";
        if(this.tipo == "servidor"){
            this.modelo_token = document.getElementsByClassName("ckeditor5 servidor")[0].getAttribute("modelo_token");
            this.modelo = document.getElementsByClassName("ckeditor5 servidor")[0].getAttribute("modelo");
        }
    }

    // Starts the upload process.
    upload() {
        return new Promise((resolve, reject) => {
            this.loader.file.then((filenew) => {
                const data = new FormData();

                data.append( 'file', filenew, filenew.name);
                data.append( 'file_type', filenew.type);
                data.append('authenticity_token', $("#authenticity_token_ckeditor").val());
                if(this.tipo == "servidor") {
                    data.append('modelo_token', this.modelo_token);
                    data.append('modelo', this.modelo);
                }
                $.ajax({
                    url: this.url,
                    data,
                    contentType: false,
                    processData: false,
                    async: true,
                    cache: false,
                    type: 'POST',
                    success: response => {
                        if(response.uploaded)
                            resolve({
                                default: response.url
                            });
                        else
                            reject(response.error)
                    },
                    error: () => {
                        reject('Upload failed');
                    }
                });
            });

        });


    }


}