/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


require("jquery")
import "cocoon-js";

require("@rails/ujs").start()
require("turbolinks").start()
require("packs/selec2.js")
require("@rails/activestorage").start()
require("channels")
require("packs/foundation.min.js")
require("trix")
require("@rails/actiontext")
require("chartkick")
require("chart.js")
require("file-saverjs")
require("tableexport")
require("packs/jscolor.js")
require("packs/markjs.min.js")
require("packs/src/cropper.min.js")
require("packs/src/filepicker.js")
require("packs/src/filepicker-drop.js")
require("packs/src/filepicker-ui.js")
require("packs/src/filepicker-crop.js")
require("packs/validations_ruc.js")
require("packs/custom.js")
require("packs/pdf-canvas.js")

global.ClipboardJS = require("clipboard")
require("packs/src/prism.js")
Prism.languages.csv = {
    'csv_value': /[^\r\n",]|"(?:[^"]|"")"(?!")/,
    'csv_separator': /,/
};

import jquery from 'jquery';
import CkeditorUploader from "packs/src/file_uploader_ckeditor.js"

window.jQuery = jquery;
window.$ = jquery;
$.fn.tableExport.defaultButton = "button";
$.fn.tableExport.rowDel = "\r\n";
$.fn.tableExport.charset = "charset=utf-8";
$.fn.tableExport.formatConfig.xlsx.buttonContent = "Exportar a EXCEL (.xlsx)";
$.fn.tableExport.formatConfig.csv.buttonContent = "Exportar a CSV (.csv)";
$.fn.tableExport.formatConfig.txt.buttonContent = "Exportar a TXT (.txt)";

document.addEventListener('turbolinks:load', () => {
    Prism.highlightAll();
    CargarCkEditor();
    VerifyToken();
});

window.CargarCkEditor = function () {
    function CkeditorUploadAdapterPluginBase64(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new CkeditorUploader(loader, "base64");
        };
    }

    function CkeditorUploadAdapterPluginServidor(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new CkeditorUploader(loader, "servidor");
        };
    }

    var allEditors = document.querySelectorAll('.ckeditor5');
    for (var i = 0; i < allEditors.length; ++i) {
        ClassicEditor
            .create(allEditors[i], {
                extraPlugins: [allEditors[i].classList.contains("servidor") ? CkeditorUploadAdapterPluginServidor : CkeditorUploadAdapterPluginBase64],
                heading: {
                    options: [
                        {model: 'paragraph', title: 'Párrafo', class: 'ck-heading_paragraph'},
                        {model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1'},
                        {model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2'},
                        {model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3'},
                        {model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4'},
                        {model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5'},

                    ]
                },
                removePlugins: [],
                link: {
                    decorators: {
                        isExternal: {
                            mode: 'manual',
                            label: 'Open in a new tab',
                            attributes: {
                                target: '_blank'
                            }
                        }
                    }
                },

            })
            .then(editor => {
                editor.editing.view.change(writer => {
                    if ($(".editor_tk").length > 0) {
                        writer.setStyle('min-height', '300px !important', editor.editing.view.document.getRoot());
                    } else {
                    }
                });
                editor.model.document.on( 'change:data', () => {

                } );

                editor.on('afterUndoImage', function (e) {
                    alert("123")
                });
                editor.on('afterCommandExec', function (e) {
                    alert("222")
                })
                console.log(editor);
            })
            .catch(error => {
                console.error(error);
            });
    }
}

Foundation.addToJquery($);

jQuery(document).ready($ => ($(document).foundation()));


window.formatDate = function (timestamp) {
    var d = new Date(timestamp),
        yyyy = d.getFullYear(),
        mm = ('0' + (d.getMonth() + 1)).slice(-2),
        dd = ('0' + d.getDate()).slice(-2),
        hh = d.getHours(),
        h = hh,
        min = ('0' + d.getMinutes()).slice(-2),
        ampm = 'AM';

    if (hh > 12) {
        h = hh - 12;
        ampm = 'PM';
    } else if (hh === 12) {
        h = 12;
        ampm = 'PM';
    } else if (hh == 0) {
        h = 12;
    }

    return yyyy + '-' + mm + '-' + dd + ', ' + h + ':' + min + ' ' + ampm;
}

window.VerifyToken = function () {
    var myInterval;
    var interval_delay = 5000;
    var is_interval_running = false; //Optional
    $(document).ready(function () {
        $(window).focus(function () {
            clearInterval(myInterval); // Clearing interval if for some reason it has not been cleared yet
            if (!is_interval_running) //Optional
                myInterval = setInterval(interval_function, interval_delay);
        }).blur(function () {
            clearInterval(myInterval); // Clearing interval on window blur
            is_interval_running = false; //Optional
        });
    });
    var interval_function = function () {
        is_interval_running = true; //Optional
        var token = $("#kay").val();
        if (token) {
            $.ajax({
                type: "GET",
                url: "/verify_token/" + token,
                dataType: "json",
                success: function (response) {
                    if (response.valid_token == false) {
                        $('#verify_token').foundation('open');
                        $("#kay").val("");
                    }
                }

            });
        }
    };
    $(".refresh-button").on("click", function () {
        window.location.reload(true);
    });
}

